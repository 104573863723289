const schemaData = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Belong, Inc.',
  url: 'https://belonghome.com',
  logo: 'https://s3-us-west-2.amazonaws.com/belong-web-assets/belong_og_image.jpg',
  sameAs: ['https://www.facebook.com/belonghome/', 'https://twitter.com/belonghm', 'https://www.instagram.com/belonghm/', 'https://www.youtube.com/channel/UCz9Y4FRWtSasYhH1QGFKqTg', 'https://www.linkedin.com/company/belonghq']
};

export { schemaData };
