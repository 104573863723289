import { Text, Image, Link } from '@belong/ui';
import { clsx } from 'clsx';
import { SeoHint } from 'src/features/home-page/components/seo-hint/seo-hint';
import { HOME_PAGE_STRINGS } from 'src/features/home-page/home-page.strings';
import css from './hero.module.css';

export function Hero() {
  return (
    <div className="isolate">
      <SeoHint />
      <div className="w-full relative lg:container xl:max-w-container-xl">
        <Image src="/home-page/cloud.png" alt="" className={clsx(css.leftCloud)} />
        <Image src="/home-page/cloud.png" alt="" className={clsx(css.leftSmallCloud)} />
        <Image src="/home-page/balloon.svg" alt="" className={clsx(css.balloon)} />
        <Image src="/home-page/cloud.png" alt="" className={clsx(css.rightCloud)} />
        <Image src="/home-page/sun.png" alt="" className={clsx(css.sun)} />
        <div className="z-20 relative pt-2xl">
          <div className={clsx('mx-auto mb-sm text-center', css.title)}>
            <Text as="h2" variant="h1" fontWeight="semibold">
              {HOME_PAGE_STRINGS['header.title']}
            </Text>
          </div>
          <div className={clsx('text-center mx-auto mb-xl', css.subtitle)}>
            <Text variant="h3">{HOME_PAGE_STRINGS['header.subtitle']}</Text>
          </div>
          <div className="flex justify-center w-full gap-xs md:gap-sm container">
            <div className="flex-1 md:flex-none">
              <div className="contents md:hidden">
                <Link data-testid="homeowners-cta" href="/homeowners" variant="solid" size="fluid">
                  {HOME_PAGE_STRINGS['header.ownersCta']}
                </Link>
              </div>
              <div className="hidden md:contents">
                <Link data-testid="homeowners-cta" href="/homeowners" variant="solid" size="large">
                  {HOME_PAGE_STRINGS['header.ownersCta']}
                </Link>
              </div>
            </div>
            <div className="flex-1 md:flex-none text-center">
              <div className="contents md:hidden">
                <Link href="/homes" variant="solid" size="fluid">
                  {HOME_PAGE_STRINGS['header.rentersCta']}
                </Link>
              </div>
              <div className="hidden md:contents">
                <Link href="/homes" variant="solid" size="large">
                  {HOME_PAGE_STRINGS['header.rentersCta']}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
